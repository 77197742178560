import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addVoter,
  fetchFilteredList,
} from "../../features/voterIdLookupSlice/voterIdLookupApi";
import {
  moveToPage,
  setPerPage,
  resetFilters,
  toggleColumn,
  toggleTemporaryData,
  updateFilters,
  setSort,
  setColumns,
} from "../../features/voterIdLookupSlice/voterIdLookupSlice";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import useMemory from "../../hooks/useMemory";
import Table from "../../components/table/Table";
import VoterIdLookupSteps from "../../components/steps/VoterIdLookupSteps";

const LookupTable = () => {
  const [isShowingTutorial, setIsShowingTutorial] = useState(false);

  const state = useSelector((state) => state.voterIdLookup);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useMemory("voter-id-lookup-memory", state);

  const addedTempData = useRef(false);

  useUpdateEffect(() => {
    dispatch(fetchFilteredList({ navigate, params: { page: 1 } }));
  }, [state.sort]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchFilteredList({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  const handleSubmit = () => {
    dispatch(fetchFilteredList({ navigate, params: { page: 1 } }));
  };

  const handleStartTutorial = () => {
    setIsShowingTutorial(true);
    if (state.filteredList.length === 0) {
      addedTempData.current = true;
      dispatch(toggleTemporaryData());
    }
  };

  const handleReorder = (columns) => {
    const prevMemory = JSON.parse(
      localStorage.getItem("voter-id-lookup-memory")
    );

    localStorage.setItem(
      "voter-id-lookup-memory",
      JSON.stringify({ ...prevMemory, columns })
    );

    dispatch(setColumns(columns));
  };

  return (
    <>
      <VoterIdLookupSteps
        enabled={isShowingTutorial}
        onExit={() => {
          setIsShowingTutorial(false);
          if (addedTempData.current) dispatch(toggleTemporaryData());
          addedTempData.current = false;
        }}
      />

      <Table
        state={{ ...state, data: state.filteredList }}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onSubmit={handleSubmit}
        onStartTutorial={handleStartTutorial}
        onReorder={handleReorder}
        isFocused={!isShowingTutorial}
        rowActions={{
          isDropdown: false,
          state: state.status.addVoter,
          onClick: (row) =>
            dispatch(addVoter({ navigate, voterId: row.voter_id })),
          label: "Add Voter",
          className: (isDisabled) =>
            isDisabled ? "text-black" : "text-cherry",
        }}
      />
    </>
  );
};

export default LookupTable;
