import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const links = [
  {
    name: "Instructions",
    to: "/instructions",
  },
  {
    name: "Dashboard",
    to: "/dashboard",
  },
  {
    name: "My List",
    to: "/my-list",
  },
  {
    name: "Voter ID Lookup",
    to: "/voter-id-lookup",
  },
  {
    name: "Logout",
    onClick: () => {
      localStorage.removeItem("jwt-token");
      localStorage.removeItem("user-role");
      localStorage.removeItem("user-data");
    },
    to: "/login",
  },
];

const Header2 = () => {
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);

  const isAdmin = localStorage.getItem("user-role") === "admin";
  const isAmbassador = localStorage.getItem("user-role") === "ambassador";

  const closeMenu = () => setMenuIsExpanded(false);

  return (
    <header className="flex justify-center fixed top-0 w-full bg-white z-50 h-[90px]">
      <div className="w-[90%] max-w-[1400px] flex justify-between items-center">
        <Link to="/dashboard">
          <img src="/logo.png" alt="logo" />
        </Link>

        <button
          className="lg:hidden flex justify-center items-center z-10"
          onClick={() => setMenuIsExpanded((prev) => !prev)}
        >
          {menuIsExpanded ? (
            <img src="/x-mark.svg" alt="close" className="w-9" />
          ) : (
            <img src="/bars.svg" alt="open" className="w-9" />
          )}
        </button>

        <nav
          className={`gap-5 lg:gap-7 flex flex-col lg:flex-row fixed lg:static right-0 top-0 w-[280px] bg-peach h-full py-20 px-5 lg:p-0 lg:h-auto lg:bg-transparent lg:w-auto transition-transform duration-300 ${
            menuIsExpanded
              ? "translate-x-0"
              : "translate-x-full lg:translate-x-0"
          }`}
        >
          {isAdmin && (
            <>
              <NavLink name={"Logs"} to={"/logs"} closeMenu={closeMenu} />
              <NavLink name={"Admin"} to={"/admin"} closeMenu={closeMenu} />
            </>
          )}

          {(isAdmin || isAmbassador) && (
            <NavLink
              name={"Team Management"}
              to={"/team-management"}
              closeMenu={closeMenu}
            />
          )}

          {links.map((link, index) => (
            <NavLink key={index} {...link} closeMenu={closeMenu} />
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header2;

const NavLink = ({ name, to, closeMenu, onClick = () => {} }) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={to}
      onClick={() => {
        closeMenu();
        if (onClick) onClick();
      }}
      className={`uppercase text-sm font-fira font-medium ${
        to === pathname ? "underline underline-offset-2" : ""
      }`}
    >
      {name}
    </Link>
  );
};
