import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "../features/adminSlice/adminSlice";
import myListReducer from "../features/myListSlice/myListSlice";
import voterIdLookupReducer from "../features/voterIdLookupSlice/voterIdLookupSlice";
import dashboardReducer from "../features/dashboardSlice/dashboardSlice";
import teamReducer from "../features/teamSlice/teamSlice";

const store = configureStore({
  reducer: {
    admin: adminReducer,
    myList: myListReducer,
    voterIdLookup: voterIdLookupReducer,
    dashboard: dashboardReducer,
    team: teamReducer,
  },
});

export default store;
