import React, { useState } from "react";
import { useTable } from "./Table";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../ui/Dropdown";

const ToggleColumns = () => {
  const [key, setKey] = useState(0);

  const { columns, onToggleColumn } = useTable();

  return (
    <Dropdown triggers={[key]}>
      <DropdownTrigger
        id="toggle-columns"
        className="flex items-center font-semibold gap-1 md:gap-3 border !h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative"
      >
        Toggle Columns
        <img
          src={"/chevron-down.svg"}
          className="size-6"
          width={24}
          height={24}
        />
      </DropdownTrigger>

      <DropdownMenu className="w-full">
        {columns
          .filter((col) => !col.isDisabled)
          .map((col) => (
            <DropdownOption
              key={col.key}
              className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-9 flex items-center px-2 font-medium text-sm rounded-[7px] gap-2"
              onClick={() => {
                setKey((prev) => (prev += 1));
                onToggleColumn(col.key);
              }}
            >
              {col.isActive ? (
                <img src="/check.svg" alt="check" className="w-4" />
              ) : (
                <div className="w-4" />
              )}
              <span>{col.text}</span>
            </DropdownOption>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ToggleColumns;
