import React, { createContext, useContext, useEffect, useState } from "react";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import VoterIdLookup from "./pages/VoterIdLookup";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./components/PageNotFound";
import Admin from "./pages/Admin";
import AccountSetup from "./pages/AccountSetup";
import SetPassword from "./pages/SetPassword";
import MyList from "./pages/MyList";
import MissingUserInfo from "./components/MissingUserInfo";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Resources from "./pages/Resources";
import TeamManagement from "./pages/TeamManagement";
import Logs from "./pages/Logs";

const LoginFormContext = createContext();

export const useLoginForm = () => useContext(LoginFormContext);

const APP_VERSION = "1.0.4";

const App = () => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const storedVersion = localStorage.getItem("version");

    if (!storedVersion) {
      localStorage.setItem("version", APP_VERSION);
      return null;
    }

    if (storedVersion !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem("version", APP_VERSION);
    }
  }, []);

  return (
    <LoginFormContext.Provider value={{ loginForm, setLoginForm }}>
      <ToastContainer
        position="top-center"
        autoClose={25000}
        className="text-2xl leading-[1.2] !w-[350px]"
      />

      {userInfo !== null &&
        (!userInfo.verified_email ||
          !userInfo.phone_number ||
          !userInfo.voter_id) && (
          <MissingUserInfo userInfo={userInfo} setUserInfo={setUserInfo} />
        )}

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login setUserInfo={setUserInfo} />} />
        <Route path="/account-setup/:token" element={<AccountSetup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/team-management" element={<TeamManagement />} />
        <Route path="/my-list" element={<MyList />} />
        <Route path="/voter-id-lookup" element={<VoterIdLookup />} />
        <Route path="/set-password/:token" element={<SetPassword />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/instructions" element={<Resources />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </LoginFormContext.Provider>
  );
};

export default App;
