import { createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";
import { updateUploadStatus } from "./myListSlice";

export const fetchFilteredList = createAsyncThunk(
  "myList/fetchFilteredList",
  async ({ navigate, options }, { getState, rejectWithValue }) => {
    const { perPage, sort, columns } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      {
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate,
      null,
      null,
      options,
      false
    );

    if (response?.aborted) return rejectWithValue({ aborted: true });

    return response.data;
  }
);

export const deleteVoter = createAsyncThunk(
  "myList/deleteVoter",
  async ({ navigate, id }, { getState }) => {
    const { page, perPage, sort, columns, selectedRows } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    let targetedRows = [id];
    if (selectedRows.includes(id)) targetedRows = selectedRows;

    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      {
        purpose: "delete_voter",
        page: page,
        per_page: perPage,
        sort: sort,
        filters: filters,
        voter_id_list: targetedRows,
      },
      navigate
    );

    return response.data;
  }
);

export const fetchVoters = createAsyncThunk(
  "myList/fetchVoters",
  async ({ navigate, row }) => {
    const response = await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "submit_name",
        first_name: row.first_name || "",
        last_name: row.last_name || "",
      },
      navigate
    );

    return response.data;
  }
);

export const exportTable = createAsyncThunk(
  "myList/exportTable",
  async ({ navigate }) => {
    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      { purpose: "export" },
      navigate
    );

    return response.data;
  }
);

export const upload = createAsyncThunk(
  "myList/upload",
  async ({ navigate, data }, { getState, dispatch }) => {
    const CHUNK_SIZE = 100;

    const { page, perPage, sort, columns } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    const totalChunks = Math.ceil(data.length / CHUNK_SIZE);
    let processedChunks = 0;
    let totalRowsUploaded = 0;

    for (let i = 0; i < totalChunks; i++) {
      const chunk = data.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);

      const response = await post(
        process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
        {
          data: chunk,
          purpose: "import_data",
          page,
          per_page: perPage,
          sort,
          filters,
          total_rows: data.length,
          chunk_number: i + 1,
          total_chunks: totalChunks,
        },
        navigate
      );

      processedChunks++;
      totalRowsUploaded += chunk.length;
      const progress = Math.round((processedChunks / totalChunks) * 100);

      dispatch(updateUploadStatus({ percent: progress }));

      if (i === totalChunks - 1) return response.data;
    }
  }
);

export const searchVoters = createAsyncThunk(
  "myList/searchVoters",
  async ({ navigate, formData }) => {
    const { first_name, middle_name, last_name } = formData;

    const response = await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "submit_name",
        first_name,
        middle_name,
        last_name,
      },
      navigate
    );

    return response.data;
  }
);

export const addOneVoter = createAsyncThunk(
  "myList/addOneVoter",
  async ({ navigate, params }, { getState }) => {
    const { page, perPage, sort, columns } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    const response = await post(
      process.env.REACT_APP_ADD_NEW_ROW_API_URL,
      {
        purpose: "manual_data_add",
        page,
        per_page: perPage,
        sort,
        filters,
        ...params,
      },
      navigate
    );

    return response.data;
  }
);

export const edit = createAsyncThunk(
  "myList/edit",
  async ({ navigate, email, id, attribute, value }, { getState }) => {
    const { page, perPage, sort, columns } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    const response = await post(
      process.env.REACT_APP_MODIFY_VALUE_API_URL,
      {
        email,
        id,
        attribute,
        value,
        table: "UserVoters",
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate
    );

    return response.data;
  }
);
