import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVoter,
  edit,
  exportTable,
  fetchFilteredList,
  fetchVoters,
} from "../../features/myListSlice/myListApi";
import {
  toggleAllRows,
  moveToPage,
  updateFilters,
  setPerPage,
  toggleColumn,
  resetFilters,
  toggleTemporaryData,
  setSort,
  toggleSelectRow,
  setColumns,
} from "../../features/myListSlice/myListSlice";
import useMemory from "../../hooks/useMemory";
import Table from "../../components/table/Table";
import InCompleteRowSearchResult from "./InCompleteRowSearchResult";
import AddVotersModal from "./AddVotersModal";
import TableButton from "../../components/table/TableButton";
import MyListSteps from "../../components/steps/MyListSteps";

const MyListTable = () => {
  const [_, setSearchParams] = useSearchParams();

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isShowingTutorial, setIsShowingTutorial] = useState(false);

  const state = useSelector((state) => state.myList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useMemory("my-list-memory", state);

  const addedTempData = useRef(false);
  const firstRenderRef = useRef(true);

  // handles the initial fetch, filtering, sorting of the data
  useEffect(() => {
    const controller = new AbortController();
    const options = { signal: controller.signal };

    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      dispatch(fetchFilteredList({ navigate, options }));
    } else {
      setSearchParams({ page: 1 });
      dispatch(fetchFilteredList({ navigate, options }));
    }

    return () => {
      controller.abort();
    };
  }, [...state.columns.map((c) => c.filter.value), state.sort, state.perPage]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchFilteredList({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  const handleToggleSelectRow = (row) => {
    dispatch(toggleSelectRow({ id: row.id }));
  };

  const handleSelectAllRows = () => dispatch(toggleAllRows());

  const handleStartTutorial = () => {
    setIsShowingTutorial(true);
    if (state.filteredList.length === 0) {
      addedTempData.current = true;
      dispatch(toggleTemporaryData());
    }
  };

  const handleReorder = (columns) => {
    const prevMemory = JSON.parse(localStorage.getItem("my-list-memory"));

    localStorage.setItem(
      "my-list-memory",
      JSON.stringify({ ...prevMemory, columns })
    );

    dispatch(setColumns(columns));
  };

  const handleEdit = (row, col, value) => {
    dispatch(
      edit({
        navigate,
        email: row.email,
        id: row.id,
        attribute: col.key,
        value,
      })
    );
  };

  return (
    <>
      <MyListSteps
        enabled={isShowingTutorial}
        onExit={() => {
          setIsShowingTutorial(false);
          if (addedTempData.current) dispatch(toggleTemporaryData());
          addedTempData.current = false;
        }}
      />

      {state.fetchedVoters && <InCompleteRowSearchResult />}
      {isImportModalOpen && (
        <AddVotersModal close={() => setIsImportModalOpen(false)} />
      )}

      <Table
        state={{ ...state, data: state.filteredList }}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onToggleSelectRow={handleToggleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onStartTutorial={handleStartTutorial}
        onReorder={handleReorder}
        onEdit={handleEdit}
        emptyPlaceholder={
          <div className="w-full h-[200px] flex justify-center items-center">
            <TableButton
              onClick={() => setIsImportModalOpen(true)}
              icon="/import.svg"
              className="!text-xl h-[52px]"
            >
              Add My Voter List
            </TableButton>
          </div>
        }
        tableActions={[
          {
            label: "Print My list",
            state: state.status.export,
            onClick: () => dispatch(exportTable({ navigate })),
          },
          {
            id: "add-voter-btn",
            label: "Add My Voter List",
            icon: "/import.svg",
            onClick: () => setIsImportModalOpen(true),
            condition:
              state.filteredList.length !== 0 ||
              state.status.fetchData === "loading",
          },
        ]}
        rowActions={{
          isDropdown: true,
          triggers: [state.status.actions],
          state: state.status.actions,
          options: [
            {
              label: "Find Voter ID",
              onClick: (row) => dispatch(fetchVoters({ navigate, row })),
              condition: (row) =>
                row.voter_id === "missing" && state.selectedRows.length < 2,
            },
            {
              label:
                state.selectedRows.length > 1
                  ? "Remove voters"
                  : "Remove voter",
              type: "danger",
              onClick: (row) => dispatch(deleteVoter({ navigate, id: row.id })),
            },
          ],
        }}
      ></Table>
    </>
  );
};

export default MyListTable;
