import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  updateFilters,
  moveToPage,
  setPerPage,
  toggleColumn,
  resetFilters,
  setSort,
} from "../../features/teamSlice/teamSlice";
import Table from "../../components/table/Table";
import { fetchData, importDataToEmail } from "../../features/teamSlice/teamApi";
import UploadModal from "./UploadModal";

const TeamTable = () => {
  const [_, setSearchParams] = useSearchParams();

  const [uploadEmail, setUploadEmail] = useState(null);

  const state = useSelector((state) => state.team);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const firstRenderRef = useRef(true);

  // handles the initial fetch, filtering, sorting of the data
  useEffect(() => {
    const controller = new AbortController();
    const options = { signal: controller.signal };

    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      dispatch(fetchData({ navigate, options }));
    } else {
      setSearchParams({ page: 1 });
      dispatch(fetchData({ navigate, options }));
    }

    return () => {
      controller.abort();
    };
  }, [...state.columns.map((c) => c.filter.value), state.sort, state.perPage]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchData({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  return (
    <>
      {uploadEmail && (
        <UploadModal email={uploadEmail} close={() => setUploadEmail(null)} />
      )}

      <Table
        state={state}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        rowActions={{
          isDropdown: true,
          triggers: [state.status.upload],
          options: [
            { label: "Upload", onClick: ({ email }) => setUploadEmail(email) },
          ],
        }}
      />
    </>
  );
};

export default TeamTable;
