import React from "react";
import Loading from "../Loading";

const TableButton = ({
  children,
  icon,
  state,
  onClick,
  className = "",
  id = "",
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      disabled={state === "loading"}
      className={`flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative disabled:hover:bg-transparent disabled:opacity-60 ${className}`}
    >
      {state === "loading" && (
        <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
          <Loading size={22} />
        </div>
      )}

      <span className={state === "loading" ? "opacity-0" : "opacity-100"}>
        {children}
      </span>

      {icon && <img src={icon} className="size-[1.4em]" />}
    </button>
  );
};

export default TableButton;
