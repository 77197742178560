import { createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";

export const fetchFilteredList = createAsyncThunk(
  "voterIdLookup/fetchFilteredList",
  async ({ navigate, params }, { getState }) => {
    const { perPage, sort, columns } = getState().voterIdLookup;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_VOTER_ID_LOOKUP_API_URL,
      {
        page,
        per_page: perPage,
        sort,
        filters,
        ...params,
      },
      navigate,
      null,
      null,
      {},
      false
    );

    return response.data;
  }
);

export const addVoter = createAsyncThunk(
  "voterIdLookup/addVoter",
  async ({ navigate, voterId }, { rejectWithValue }) => {
    const response = await post(
      process.env.REACT_APP_ADD_NEW_ROW_API_URL,
      {
        voter_id: String(voterId),
        purpose: "add_row_only",
      },
      navigate
    );

    if (response.status !== 200) {
      return rejectWithValue({ voterId });
    }

    return { voterId, ...response.data };
  }
);
