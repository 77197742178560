import axios from "axios";
import { toast } from "react-toastify";

export default async function post(
  url,
  params = {},
  navigate = null,
  onSuccess = () => {},
  onError = () => {},
  options = {},
  showToast = true
) {
  if (!navigate) return console.error("navigate is required");

  const token = localStorage.getItem("jwt-token");

  try {
    const response = await axios.post(url, params, {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });

    if (showToast) toast.success(response.data.message);

    if (typeof onSuccess === "function") onSuccess(response);

    console.log(response);

    return response;
  } catch (error) {
    console.log(error);
    if (axios.isCancel(error)) return { aborted: true };

    if (error.response) {
      if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Unauthorized, redirecting to login...");
        navigate("/login");
      }

      console.error(error);
      toast.error(error.response?.data.error);

      if (typeof onError === "function") onError(error);

      return error.response;
    } else if (error.request) {
      toast.error(
        "No response received from the server. Please check your internet connection."
      );
    }
  }
}
