import React, { useEffect, useRef } from "react";
import DropdownFilter from "./DropdownFilter";
import SearchFilter from "./SearchFilter";
import Button from "../ui/Button";
import MultiSelectFilter from "./MultiSelectFilter";
import { useTable } from "./Table";
import { useDispatch } from "react-redux";
import { fetchFilteredList } from "../../features/voterIdLookupSlice/voterIdLookupApi";
import { useNavigate } from "react-router-dom";

const Filters = () => {
  const {
    data,
    columns,
    onSubmit,
    status,
    onSelectAllRows,
    selectedRows,
    isFocused,
  } = useTable();

  const rowRef = useRef();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") onSubmit();
  };

  useEffect(() => {
    if (onSubmit && isFocused) {
      if (status.fetchData !== "loading")
        window.addEventListener("keypress", handleKeyPress);
      return () => window.removeEventListener("keypress", handleKeyPress);
    }
  }, [status.fetchData, isFocused]);

  return (
    <tr ref={rowRef} className="border-b">
      {onSelectAllRows && selectedRows && (
        <td>
          <div
            id="select-all-rows"
            className="flex justify-center text-sm lg:text-base"
          >
            <input
              type="checkbox"
              className="checkbox checkbox-black"
              checked={
                data.length !== 0 &&
                data.every((row) => selectedRows.includes(row.id))
              }
              onChange={onSelectAllRows}
            />
          </div>
        </td>
      )}

      {columns
        .filter((col) => col.isDisabled !== true)
        .filter((col) => col.isActive)
        .map((col) => {
          switch (col.filter.type) {
            case "search":
              return (
                <SearchFilter
                  key={col.key}
                  colKey={col.key}
                  {...col.filter}
                  shouldDebounce={!onSubmit}
                />
              );
            case "dropdown":
              return (
                <DropdownFilter
                  key={col.key}
                  colKey={col.key}
                  {...col.filter}
                  shouldDebounce={!onSubmit}
                />
              );
            case "multiselect":
              return (
                <MultiSelectFilter
                  key={col.key}
                  colKey={col.key}
                  {...col.filter}
                  shouldDebounce={!onSubmit}
                />
              );
          }
        })}

      {onSubmit && (
        <td className="h-[52px] py-1.5 sticky right-0 bg-white min-w-[100px] w-[150px]">
          <Button
            id="search-btn"
            state={status.fetchData}
            disabled={status.fetchData === "loading"}
            onClick={onSubmit}
            className="text-[13px] lg:text-[15px] !px-1 w-full h-full"
          >
            Search
          </Button>
        </td>
      )}
    </tr>
  );
};

export default Filters;
