import React, { createContext, useContext, useEffect, useState } from "react";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import Loading from "../Loading";

const DropdownContext = createContext();

export const Dropdown = ({
  children,
  className = "",
  parent,
  triggers = [],
  defaultZIndex = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (parent) {
      if (isOpen) document.querySelector(parent).style.zIndex = 60;
      else document.querySelector(parent).style.zIndex = defaultZIndex || 0;
    }
  }, [isOpen]);

  useUpdateEffect(() => {
    if (triggers.some((trigger) => trigger === "loading")) setIsLoading(true);
    else {
      setIsOpen(false);
      setIsLoading(false);
    }
  }, triggers);

  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen, isLoading }}>
      <div
        onClick={() => setIsOpen(false)}
        className={`fixed inset-0 ${
          isOpen ? "pointer-events-auto z-20" : "pointer-events-none"
        }`}
      ></div>

      <div className={`relative ${isOpen ? "z-30" : "z-0"} ${className}`}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export const DropdownTrigger = ({ children, className = "", ...props }) => {
  const { setIsOpen } = useContext(DropdownContext);

  return (
    <button
      type="button"
      onClick={() => setIsOpen((prev) => !prev)}
      className={`w-full h-full flex justify-center items-center ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export const DropdownMenu = ({ children, state, className = "", ...props }) => {
  const { isOpen } = useContext(DropdownContext);

  return (
    <div
      className={`min-w-max absolute bg-white border transition-all duration-200 p- rounded-md z-50 right-0 ${
        isOpen
          ? "opacity-100 translate-y-1 pointer-events-auto"
          : "opacity-0 translate-y-0 pointer-events-none"
      } ${className}`}
      {...props}
    >
      {state === "loading" && (
        <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
          <Loading size={20} />
        </div>
      )}

      <ul className={state === "loading" ? "opacity-0" : "opacity-100"}>
        {children}
      </ul>
    </div>
  );
};

export const DropdownOption = ({
  children,
  type = "",
  className = "",
  onClick = () => {},
  ...props
}) => {
  const { isLoading } = useContext(DropdownContext);

  return (
    <li
      {...props}
      onClick={() => {
        if (!isLoading) onClick();
      }}
      className={`!leading-none text-[15px] py-2 px-2 rounded-[5px] group relative ${
        isLoading
          ? "cursor-default"
          : type !== "heading"
          ? "cursor-pointer hover:bg-[#F9F9FA]"
          : ""
      } ${className}`}
    >
      <span
        className={`${type === "danger" ? "text-cherry" : ""} ${
          type === "success" ? "text-green-600" : ""
        } ${type === "heading" ? "font-semibold" : ""} ${
          isLoading ? "opacity-60" : "opacity-100"
        }`}
      >
        {typeof children === "object" ? children[0] : children}
      </span>

      {typeof children === "object" ? children[1] : null}
    </li>
  );
};

export const SubDropdownMenu = ({
  children,
  className = "",
  state,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`absolute opacity-0 pointer-events-none translate-y-1 group-hover:opacity-100 group-hover:pointer-events-auto group-hover:translate-y-0 transition-all duration-200 right-full top-0 bg-white border rounded-md -translate-x-2 before:absolute before:w-3 before:h-full before:left-full p-1 text-black before:top-0 ${className}`}
    >
      {state === "loading" && (
        <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
          <Loading size={20} />
        </div>
      )}

      <ul className={state === "loading" ? "opacity-0" : "opacity-100"}>
        {children}
      </ul>
    </div>
  );
};
