import React from "react";
import Header2 from "../../layout/Header2";
import Footer from "../../layout/Footer";
import TeamTable from "./TeamTable";

const TeamManagement = () => {
  return (
    <>
      <Header2 />

      <div className="w-full flex justify-center mt-[110px] xs:mt-[120px] md:mt-[140px] font-mont">
        <div
          id="team-management"
          className="w-[90%] max-w-[1400px] flex items-start gap-3"
        >
          <TeamTable />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TeamManagement;
