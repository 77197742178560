import React from "react";
import TableButton from "./TableButton";
import { useTable } from "./Table";

const PageHelper = () => {
  const { onStartTutorial } = useTable();

  return <TableButton onClick={onStartTutorial}>Page Helper</TableButton>;
};

export default PageHelper;
