import React, { useState } from "react";
import Button from "../../components/ui/Button";
import { STEPS } from "./Main";
import { useParams } from "react-router-dom";
import Input from "../../components/ui/Input";
import useAxios from "../../hooks/useAxios";

const InputPhoneStep = ({ setStep }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonState, setButtonState] = useState("");

  const { token } = useParams();
  const { post } = useAxios();

  const handleChange = (e) => setPhoneNumber(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setButtonState("loading");

    await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "update_phone_number",
        phone_number: phoneNumber,
        token,
      },
      () => setStep(STEPS.INPUT_FULL_NAME)
    );

    setButtonState("");
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-[18px]">
      <p className="px-4">Please enter your phone number below</p>

      <div className="flex flex-col gap-3 px-4">
        <Input
          variant="phone"
          name="phone_number"
          value={phoneNumber}
          onChange={handleChange}
        />
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full px-4 flex justify-end gap-2">
        <Button
          state={buttonState}
          type="submit"
          disabled={phoneNumber === "" || buttonState === "loading"}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default InputPhoneStep;
