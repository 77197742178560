import { createSlice } from "@reduxjs/toolkit";
import initialState, { DEFAULT_SORT, DEFAULT_COLUMNS } from "./initialState";
import {
  addOneVoter,
  deleteVoter,
  exportTable,
  fetchFilteredList,
  fetchVoters,
  searchVoters,
  upload,
  edit,
} from "./myListApi";
import downloadAsCsv from "../../utils/downloadAsCsv";

const TEMPORARY_DATA = [
  {
    first_name: "John",
    last_name: "Doe",
    voted: "0",
    voter_id: "9999999999",
  },
];

const myListSlice = createSlice({
  name: "myList",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      const [key, newFilterValue] = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key !== key) return col;
        return { ...col, filter: { ...col.filter, value: newFilterValue } };
      });
    },

    moveToPage: (state, action) => {
      state.page = action.payload;
    },

    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },

    toggleColumn: (state, action) => {
      const key = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key === key)
          return {
            ...col,
            isActive: !col.isActive,
            filter: { ...col.filter, value: "" },
          };
        return col;
      });

      if (state.sort.column === key) state.sort = { column: null, order: null };
    },

    resetFilters: (state) => {
      localStorage.removeItem("my-list-memory");

      state.columns = DEFAULT_COLUMNS.map((col, i) => ({
        ...col,
        isActive: state.columns[i].isActive,
      }));
      state.perPage = 10;
      state.sort = DEFAULT_SORT;
    },

    setSort: (state, action) => {
      state.sort = action.payload;
    },

    setColumns: (state, action) => {
      state.columns = action.payload;
    },

    toggleSelectRow: (state, action) => {
      const { id } = action.payload;

      if (state.selectedRows.includes(id)) {
        state.selectedRows = state.selectedRows.filter(
          (voterId) => voterId !== id
        );
      } else {
        state.selectedRows = [...state.selectedRows, id];
      }
    },

    toggleAllRows: (state) => {
      const allRowsSelected =
        state.filteredList.length !== 0 &&
        state.filteredList.every((row) => state.selectedRows.includes(row.id));

      if (allRowsSelected) {
        state.selectedRows = state.selectedRows.filter((prevId) => {
          return !state.filteredList.find((row) => row.id === prevId);
        });
      } else {
        state.selectedRows = state.filteredList.map((row) => row.id);
      }
    },

    clearFetchedVoters: (state) => {
      state.fetchedVoters = null;
    },

    clearSearchedVoters: (state) => {
      state.searchedVoters = null;
    },

    updateUploadStatus: (state, action) => {
      const { percent } = action.payload;
      state.status.upload = percent;
    },

    toggleTemporaryData: (state) => {
      if (state.filteredList.length === 0) state.filteredList = TEMPORARY_DATA;
      else state.filteredList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredList.pending, (state) => {
        state.status.fetchData = "loading";
      })
      .addCase(fetchFilteredList.fulfilled, (state, action) => {
        state.status.fetchData = "idle";
        state.filteredList = action.payload.data || [];
        state.totalPages = action.payload.metadata.total_pages;
      })
      .addCase(fetchFilteredList.rejected, (state, action) => {
        if (!action.payload?.aborted) state.status.fetchData = "idle";
      })

      .addCase(deleteVoter.pending, (state) => {
        state.status.actions = "loading";
      })
      .addCase(deleteVoter.fulfilled, (state, action) => {
        state.status.actions = "idle";
        state.filteredList = action.payload.data || [];
        state.totalPages = action.payload.metadata.total_pages;
        state.selectedRows = [];
      })
      .addCase(deleteVoter.rejected, (state) => {
        state.status.actions = "idle";
      })

      .addCase(fetchVoters.pending, (state) => {
        state.status.actions = "loading";
      })
      .addCase(fetchVoters.fulfilled, (state, action) => {
        state.status.actions = "idle";
        state.fetchedVoters = action.payload.data;
      })
      .addCase(fetchVoters.rejected, (state) => {
        state.status.actions = "idle";
      })

      .addCase(exportTable.pending, (state) => {
        state.status.export = "loading";
      })
      .addCase(exportTable.fulfilled, (state, action) => {
        state.status.export = "idle";
        if (action.payload.data?.export_data) {
          downloadAsCsv(action.payload.data.export_data);
        }
      })
      .addCase(exportTable.rejected, (state) => {
        state.status.export = "idle";
      })

      .addCase(upload.pending, (state) => {
        state.status.upload = 0;
      })
      .addCase(upload.fulfilled, (state, action) => {
        state.filteredList = action.payload.data || [];
        state.totalPages = action.payload.metadata.total_pages;
        state.status.upload = "idle";
      })
      .addCase(upload.rejected, (state) => {
        state.status.upload = "idle";
      })

      .addCase(searchVoters.pending, (state) => {
        state.status.search = "loading";
      })
      .addCase(searchVoters.fulfilled, (state, action) => {
        state.status.search = "idle";
        state.searchedVoters = action.payload.data;
      })
      .addCase(searchVoters.rejected, (state) => {
        state.status.search = "idle";
      })

      .addCase(addOneVoter.pending, (state) => {
        state.status.addOneVoter = "loading";
      })
      .addCase(addOneVoter.fulfilled, (state, action) => {
        state.status.addOneVoter = "idle";
        state.filteredList = action.payload.data || [];
        state.totalPages = action.payload.metadata.total_pages;
      })
      .addCase(addOneVoter.rejected, (state) => {
        state.status.addOneVoter = "idle";
      })

      .addCase(edit.pending, (state) => {
        state.status.edit = "loading";
      })
      .addCase(edit.fulfilled, (state, action) => {
        state.status.edit = "idle";
        if (action.payload.data) {
          state.filteredList = action.payload.data;
        }
        if (action.payload.metadata) {
          state.totalPages = action.payload.metadata.total_pages;
        }
      })
      .addCase(edit.rejected, (state) => {
        state.status.edit = "idle";
      });
  },
});

export const {
  updateFilters,
  moveToPage,
  setPerPage,
  toggleColumn,
  resetFilters,
  setSort,
  setColumns,
  toggleSelectRow,
  toggleAllRows,
  clearFetchedVoters,
  updateUploadStatus,
  clearSearchedVoters,
  toggleTemporaryData,
} = myListSlice.actions;

export default myListSlice.reducer;
