import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  addOneVoter,
  searchVoters,
} from "../../features/myListSlice/myListApi";
import { useNavigate } from "react-router-dom";

const ManualSearch = ({ close, setModalType }) => {
  const [showAddUserButton, setShowAddUserButton] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
  });

  const state = useSelector((state) => state.myList);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.first_name === "" || formData.last_name === "") return;

    const { payload } = await dispatch(searchVoters({ navigate, formData }));

    if (payload.data?.length > 0) {
      setModalType("results");
      clearForm();
    } else setShowAddUserButton(true);
  };

  const handleAddUser = async () => {
    const lowerFormData = Object.keys(formData).reduce((acc, curr) => {
      return { [curr]: formData[curr].toLowerCase(), ...acc };
    }, {});

    const { payload } = await dispatch(
      addOneVoter({
        navigate,
        params: { voter_id: "missing", ...lowerFormData },
      })
    );

    clearForm();
    setShowAddUserButton(false);
    close();
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 xs:p-5 flex flex-col gap-4">
      <div className="space-y-0.5">
        <h6 className="text-[#323232] font-semibold text-lg">Instructions</h6>
        <p className="text-[#969696] leading-[1.35] text-lg">
          Enter the first, middle, and last name manually to search for matching
          records in the database.
        </p>
      </div>

      <div className="flex flex-col gap-2.5 text-lg">
        <Input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={formData.first_name}
          onChange={handleChange}
        />

        <Input
          type="text"
          name="middle_name"
          placeholder="Middle Name"
          value={formData.middle_name}
          onChange={handleChange}
        />

        <Input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={formData.last_name}
          onChange={handleChange}
        />
      </div>

      <div className="flex gap-2 justify-end">
        {showAddUserButton && (
          <Button
            type="button"
            state={state.status.addOneVoter}
            disabled={
              formData.first_name === "" ||
              formData.last_name === "" ||
              state.status.search === "loading" ||
              state.status.addOneVoter === "loading"
            }
            onClick={handleAddUser}
            className="w-full h-[48px] text-lg !rounded-lg bg-blue-500 hover:bg-blue-600 flex-grow"
          >
            Add Without VUID
          </Button>
        )}

        <Button
          type="submit"
          state={state.status.search}
          disabled={
            formData.first_name === "" ||
            formData.last_name === "" ||
            state.status.search === "loading" ||
            state.status.addOneVoter === "loading"
          }
          className={`w-full h-[48px] text-lg !rounded-lg ${
            showAddUserButton ? "w-1/2" : "w-full"
          }`}
        >
          Search
        </Button>
      </div>
    </form>
  );
};

export default ManualSearch;
