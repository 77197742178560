export const DEFAULT_COLUMNS = [
  {
    key: "voter_id",
    text: "Voter ID",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "first_name",
    text: "First Name",
    canEdit: true,
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "last_name",
    text: "Last Name",
    canEdit: true,
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "phone_number",
    text: "Phone Number",
    isActive: true,
    isDisabled: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "voted",
    text: "Voted",
    isActive: true,
    filter: { type: "dropdown", value: "", values: ["Voted", "Not Voted"] },
  },
  {
    key: "g_score",
    text: "G Score",
    isActive: true,
    isDisabled: true,
    tooltip:
      "Measures the likelihood of a voter participating in general elections. A higher score indicates a more reliable voter.",
    filter: { type: "search", value: "" },
  },
  {
    key: "r_score",
    text: "R Score",
    isActive: true,
    isDisabled: true,
    tooltip:
      "Measures the likelihood of a voter supporting Republican candidates. A higher score indicates stronger Republican support.",
    filter: { type: "search", value: "" },
  },
  {
    key: "rank",
    text: "Rank",
    isActive: true,
    isDisabled: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "general_voting_history",
    text: "General Voting History",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "primary_voting_history",
    text: "Primary Voting History",
    isActive: true,
    filter: { type: "search", value: "" },
  },
];

export const DEFAULT_SORT = { column: null, order: null };
export const DEFAULT_PER_PAGE = 10;

const memory = JSON.parse(localStorage.getItem("my-list-memory"));

export default {
  filteredList: [],
  totalPages: 0,
  page: 1,
  perPage: memory?.perPage || DEFAULT_PER_PAGE,
  sort: memory?.sort || DEFAULT_SORT,
  columns: memory?.columns || DEFAULT_COLUMNS,
  selectedRows: [],
  fetchedVoters: null,
  searchedVoters: null,
  status: {
    fetchData: "idle",
    actions: "idle",
    export: "idle",
    upload: "idle",
    search: "idle",
    addOneVoter: "idle",
    edit: "idle",
  },
};
