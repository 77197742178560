import useUpdateEffect from "./useUpdateEffect";

const useMemory = (key, state) => {
  useUpdateEffect(() => {
    localStorage.setItem(
      key,
      JSON.stringify({
        columns: state.columns,
        sort: state.sort,
        perPage: state.perPage,
      })
    );
  }, [...state.columns, state.sort, state.perPage]);

  return;
};

export default useMemory;
